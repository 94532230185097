import {nextTick} from 'vue';
import EngineCommon from './engineCommon'
export default class EngineUtil {
    public dataObj:any;
    public proxy:any;
    public utils:any;

    constructor(dataObj:any,proxy:any,utils:any) {
        this.dataObj=dataObj;
        this.proxy=proxy;
        this.utils=utils;
    }


    public async doAddOrLoad(id:string,type:string='card'):Promise<void>{
        this.dataObj.loading=true;
        if(EngineCommon.existMethod(this.dataObj,'addOrLoad')){
            await EngineCommon.execMethod(this.proxy,'addOrLoad')
        }else{
            let addOrLoad = this.utils.UtilPub.isNotEmpty(id)?'/load':'/add';
            let url = this.dataObj.otherParams.compParams.modelPath + addOrLoad;
            let params={};

            if(EngineCommon.existMethod(this.dataObj,'setParam'))params=await EngineCommon.execMethod(this.proxy,'setParam')||{};
            let billModel=this.dataObj.otherParams.compParams.billModel;
            if('bill'==type){
                params=Object.assign({id: id,modelType:'bill',billModel:billModel}, params);
            }else{
                params=Object.assign({id: id,modelType:'card',isBuildPageFromBack:true}, this.dataObj.otherParams.compParams,params);
                this.dataObj.id=id;
            }

            let res = await this.utils.Api.postRequest({url: url, params: params});
            if(res.result){


                EngineCommon.getModelCompRef(this.proxy).form = res.data;
                this.dataObj.buttons = res.buttons;
                if(res.pageInfo){
                    let pageInfo=JSON.parse(res.pageInfo);
                    if(pageInfo.formItems)EngineCommon.getCompParams(this.proxy).formItems=pageInfo.formItems;
                    if(pageInfo.detailInfos)EngineCommon.getCompParams(this.proxy).detailInfos=pageInfo.detailInfos;
                }
            }

            await EngineCommon.execMethod(this.proxy,'beforeOpen',{res:res,addOrLoad:addOrLoad});

            if (this.dataObj.otherParams.compParams.hasDetails && this.dataObj.otherParams.compParams.details){
                let details=this.dataObj.otherParams.compParams.details;
                for (const detailType of details) {

                    if(EngineCommon.existMethod(this.dataObj,'loadDetails')){
                        await EngineCommon.execMethod(this.proxy,'loadDetails',{detailType:detailType,id:id,addOrLoad:addOrLoad});
                    }else{
                        await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).reloadGrid({detailType:detailType,mainId:id,addOrLoad:addOrLoad});
                    }
                }
            }
            if(EngineCommon.existMethod(this.dataObj,'afterOpened'))await EngineCommon.execMethod(this.proxy,'afterOpened',{res:res,addOrLoad:addOrLoad});
        }

        this.dataObj.loading=false;
    }
    //保存
    public async doSave(engineParams?:any,type?:string,dialogInst?:any,closeDialog:boolean=true):Promise<boolean>{

        this.dataObj.loading=true;

        if (this.dataObj.otherParams.compParams.hasDetails) {
            if(EngineCommon.existMethod(this.dataObj,'setDetailsJson')){
                await EngineCommon.execMethod(this.proxy,'setDetailsJson');
            }else{
                let details=this.dataObj.otherParams.compParams.details;
                for (const detailType of details) {
                    let detailData=await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).getSaveDetailData();
                    if(detailData){
                        EngineCommon.getModelCompRef(this.proxy).form[detailType]=detailData;
                    }else{
                        this.dataObj.loading=false;
                        return false;
                    }
                }
            }
        }

        if(EngineCommon.existMethod(this.dataObj,'beforeSaveHandler')){
            if(!await EngineCommon.execMethod(this.proxy,'beforeSaveHandler')){
                this.dataObj.loading=false;//关闭遮罩层
                return false;
            }
        }

        let url = this.dataObj.otherParams.compParams.modelPath + "/save";

        let params=Object.assign(EngineCommon.getModelCompRef(this.proxy).form, this.dataObj.submitData);
        try {

            let res = await this.utils.Api.postRequest({url: url, params: params});
            if(res.result){
                this.utils.Tools.success({message: res.msg});

                await this.doAddOrLoad(res.data.id,type);

                if (this.dataObj.otherParams.compParams.hasDetails){
                    let details=this.dataObj.otherParams.compParams.details;
                    for (const detailType of details) {

                        if(EngineCommon.existMethod(this.dataObj,'loadDetails')){
                            await EngineCommon.execMethod(this.proxy,'loadDetails',{detailType:detailType,id:res.data.id,addOrLoad:'/load'});
                        }else{
                            await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).reloadGrid({detailType:detailType,mainId:res.data.id});
                        }
                    }
                }

                if(EngineCommon.existMethod(this.dataObj,'afterSaveHandler')){
                    if(!await EngineCommon.execMethod(this.proxy,'afterSaveHandler',{res:res})){
                        this.dataObj.loading=false;//关闭遮罩层
                        return false;
                    }
                }

                if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler(false);
                if(closeDialog)this.closeDialog(dialogInst);
            }else{
                this.dataObj.loading=false;
                return false;
            }
        } catch (e) {
            this.dataObj.loading=false;
            return false;
        }
        return true;
    }
    //删除
    public async doDelete(engineParams:any,dialogInst:any):Promise<void>{
        this.dataObj.loading=true;//打开正在加载效果
        let id=EngineCommon.getModelCompRef(this.proxy).form.id;
        if(EngineCommon.existMethod(this.dataObj,'beforeDeleteHandler')){
            if(!await EngineCommon.execMethod(this.proxy,'beforeDeleteHandler',{id:id})){
                this.dataObj.loading=false;//关闭遮罩层
                return;
            }
        }

        let url = this.dataObj.otherParams.compParams.modelPath + '/delete';

        let res = await this.utils.Api.postRequest({url: url, params: {id:id}});
        //关闭正在加载效果
        this.dataObj.loading=false;
        if(res.result){
            this.utils.Tools.success({message: res.msg});
            if(EngineCommon.existMethod(this.dataObj,'afterDeleteHandler'))await EngineCommon.execMethod(this.proxy,'afterDeleteHandler',{id:id})

            if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
            this.closeDialog(dialogInst);
        }
    }
    //重置
    public async doReset(type:string):Promise<void>{

        let id=EngineCommon.getModelCompRef(this.proxy).form.id;
        if (this.utils.UtilPub.isNotEmpty(id)) {
            await this.doAddOrLoad(id,type);
        } else {
            EngineCommon.getModelCompRef(this.proxy).formRef.resetFields([]);
            if (this.dataObj.otherParams.compParams.hasDetails && this.dataObj.otherParams.compParams.details){
                let details=this.dataObj.otherParams.compParams.details;
                for (const detailType of details) {
                    await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).clearDetailData();
                }
            }
        }
        this.utils.Tools.success();
    }
    //保存事件
    public async saveHandler(engineParams:any,type:string='card',dialogInst:any):Promise<void>{

        if(EngineCommon.existMethod(this.dataObj,'saveHandler')){
            await EngineCommon.execMethod(this.proxy,'saveHandler');
        } else {

            await EngineCommon.getModelCompRef(this.proxy).formRef.validate(async (valid:BufferSource) =>{
                if(valid){//如果验证通过

                    if(this.dataObj.otherParams.compParams.saveConfirm){
                        let message=this.dataObj.otherParams.compParams.saveConfirmContent;
                        if(!message)message=this.proxy.$t('cardEngine.saveTip');
                        this.utils.Tools.configBox({
                            message:message,
                            sureFn:async ()=> await this.doSave(engineParams,type,dialogInst),
                            cancelFn:()=>this.dataObj.loading=false
                        });
                    }else{
                        await this.doSave(engineParams,type,dialogInst)
                    }
                }
            });
        }
    }
    //删除事件
    public async delHandler(engineParams:any,id:any,dialogInst:any):Promise<void>{
        if(this.utils.UtilPub.isNotEmpty(id)){
            this.utils.Tools.configBox({
                message:this.proxy.$t('cardEngine.deleteTip'),
                sureFn:async ()=>{
                    await this.doDelete(engineParams,dialogInst);
                }
            });
        }else{
            this.proxy.$message(this.proxy.$t('cardEngine.addToDeleteTip'));
        }
    }
    //重置事件
    public async resetHandler(type:string='card'):Promise<void>{

        if (EngineCommon.getModelCompRef(this.proxy)['resetHandler'] && !this.dataObj.otherParams.compParams.isEnginePage) {
            EngineCommon.getModelCompRef(this.proxy)['resetHandler'](this.proxy);
        } else {
            await this.dataObj.engineUtil.doReset(type);
        }
    }

    public closeDialog(dialogInst:any):void{
        dialogInst.dialogVisible=false;
    }
    //动画
    public async animation():Promise<void>{
        await nextTick(()=>{

        })
    }
}