import BaseBean from "@/utils/BaseBean";
import {inject} from "vue";
export interface ICardEngineDataObj {
    utilInst:CardEngineUtil
    engineUtil:any
    refMap:Map<string,any>
    id:string
    mainMaxHeight:number
    loading:boolean
    buttons:Array<any>
    modelComp:any
    modelCompRef:any
    formRef:any
    form:any
    otherParams:any
}
export default class CardEngineUtil extends BaseBean{
    dialogInst = inject('dialogInst') as any;
    public dataObj:ICardEngineDataObj;
    public props:any
    public context:any
    constructor(proxy:any,dataObj:ICardEngineDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public initDataObjParams():void{

        if(this.props.engineParams.compParams)Object.assign(this.dataObj.otherParams.compParams,this.props.engineParams.compParams);

        if(!this.dataObj.otherParams.compParams.isEnginePage)Object.assign(this.dataObj.otherParams.compParams,this.dataObj.modelCompRef.compParams);//业务模块的参数
        if(this.props.engineParams.compParams && this.props.engineParams.compParams.form)Object.assign(this.dataObj.form,this.props.engineParams.compParams.form);

        this.dataObj.mainMaxHeight=(window.innerHeight-103);
        this.dataObj.modelCompRef.engine=this.proxy;
        this.dataObj.modelCompRef.dialogInst=this.dialogInst;
    }
}