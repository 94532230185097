export default class EngineCommon {

    static existMethod(dataObj:any,methodName:string):boolean{
        let eventName=dataObj.otherParams.compParams.modelPath+'_'+methodName;
        if(dataObj.otherParams.compParams.isEnginePage){
            return window.customEvent.indexOf(eventName)>-1?true:false;
        }else{
            return dataObj.modelCompRef[methodName]?true:false;
        }
    }
    static async execMethod(engineInst:any,methodName:string,params:any={}):Promise<any>{
        params.engineInst=engineInst;
        if(engineInst.otherParams.compParams.isEnginePage){
            let eventName=engineInst.otherParams.compParams.modelPath+'_'+methodName;

        }else{
            return await engineInst.modelCompRef[methodName](...Object.values(params));
        }
    }

    static getModelCompRef(engineInst:any):any{
        return engineInst.otherParams.compParams.isEnginePage?engineInst:engineInst.modelCompRef;
    }

    static getCompParams(engineInst:any):any{
        return engineInst.otherParams.compParams.isEnginePage?engineInst.otherParams.compParams:engineInst.modelCompRef.compParams;
    }
}