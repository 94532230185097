import {reactive, toRefs, onBeforeMount, onMounted, getCurrentInstance, nextTick, defineComponent, inject, computed} from 'vue'
import CardEngineUtil,{ICardEngineDataObj} from "@/components/base_comp/cardEngine/cardEngineUtil";
import EngineUtil from "@/components/base_comp/cardEngine/engineUtil";
import EngineCommon from "@/components/base_comp/cardEngine/engineCommon";
const CardEngineHelper = defineComponent({
    name: 'CardEngine',
    props: {

        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const engineParams=props.engineParams as any;
        let dialogInst = inject('dialogInst') as any;
        let dataObj:ICardEngineDataObj=reactive<ICardEngineDataObj>({
            utilInst:{} as any,
            engineUtil:{} as any,
            refMap:new Map(),
            id:'',
            mainMaxHeight:400,
            loading:false,
            buttons:[],
            modelComp:engineParams.modelComp,
            modelCompRef: {},
            formRef:null,
            form:{},
            otherParams:{
                compParams:{
                    isEnginePage:false,
                    colNum:1,
                    rules:{},
                    disabled:false,
                    hasDetails: false,
                    oneDetail:true
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.engineUtil=new EngineUtil(dataObj,proxy,utils);
            dataObj.utilInst=new CardEngineUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await nextTick(async () => {
                dataObj.utilInst.initDataObjParams();

                await dataObj.engineUtil.doAddOrLoad(engineParams.id,'card');
                await dataObj.engineUtil.animation();
                if(dataObj.otherParams.compParams.detailInfos && Object.keys(dataObj.otherParams.compParams.detailInfos).length>1)dataObj.otherParams.compParams.oneDetail=false;
            })
        })
        const clickHandler=(ev:string)=>{

            if (EngineCommon.getModelCompRef(proxy)[ev]) {
                EngineCommon.getModelCompRef(proxy)[ev](proxy);
            } else {
                proxy[ev]();
            }
        }

        const saveHandler=async ()=>{
            await dataObj.engineUtil.saveHandler(props.engineParams,'card',dialogInst)
        }
        const resetHandler=async()=>{
            await dataObj.engineUtil.resetHandler();
        }

        const delHandler=async ()=>{
            await dataObj.engineUtil.delHandler(engineParams,dataObj.id,dialogInst);
        }
        const detailParam=computed(()=>{
            return (options:any) => {
                return {
                    detailParam: {
                        title:options.title,
                        readOnly:dataObj.otherParams.compParams.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: options.detailType
                        },
                        modelMethod: dataObj.otherParams.compParams.modelPath+"/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        return{
            ...toRefs(dataObj),clickHandler,saveHandler,resetHandler,delHandler,detailParam
        }
    }
});
export default CardEngineHelper;